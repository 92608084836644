<template>

    <DefaultView>

        <Transition :name="this.viewConfig.view === 'configuration' ? 'slide-forward' : 'slide-back'">

            <CompanyConfig
                v-if="this.viewConfig.view === 'configuration'"
                :onclose="() => this.viewConfig.view = 'dashboard'"
                :viewConfig="this.viewConfig"/>

            <div v-else class="flex flex-col gap-4 overflow-hidden">

                <!---- Header ---->

                <div class="flex flex-col gap-3 border-b border-white/50 pb-3">

                    <p class="text-lg font-medium">Altas &nbsp;|&nbsp; Historial y Pendientes</p>

                    <div class="w-full flex flex-wrap items-center justify-between gap-2">

                        <div class="flex gap-2">

                            <Button variant="outlined_light" size="sm" text="Configuración de empresas y grupos" icon="icon-gear" @click="this.viewConfig.view = 'configuration'"/>
                            <Button variant="outlined_light" size="sm" text="Configuración de formulario" icon="icon-form" @click="this.ManageFormToFlowConfiguration()"/>

                        </div>

                        <div class="flex items-center gap-3 ml-auto">

                            <Button variant="outlined_light" size="sm" text="" icon="icon-refresh" @click="this.Init"/>
                            <Button variant="outlined_light" size="sm" text="Nueva Alta" icon="" @click="HandleSubmissionModal(false)"/>
                            <Button text="Sincronizar con A3" size="sm" class="secondary" icon="link-trash" :loading="viewConfig.loading.running" @click="this.OnSubmissionSyncWithA3" />
                            <Button variant="primary" size="sm" text="Altas Automáticas S. RED" :loading="viewConfig.loading.running" @click="this.OnSubmissionsLaunch()"/>

                        </div>

                    </div>

                </div>

                <div class="flex items-center gap-2 justify-between">

                    <Dropdown
                        size="sm"
                        :items="filterByCompanyDropdownItems"
                        :floating="true"
                        v-model="filterByCompany"
                        class="max-w-sm"/>

                </div>

                <div v-if="this.viewConfig.loading.submissions">

                    <div class="h-12 w-12 loading-spinner-main mx-auto"/>

                </div>

                <div v-else class="w-full flex flex-col gap-6 overflow-y-auto scroll pr-0.5 pb-6">

                    <!---------- Pending list ---------->

                    <SubmissionsList
                        :data="{
                            label: 'Pendientes',
                            items: this.SubmissionsByType.pendings,
                            trashCallback: this.RemoveSelectedSubmissions,
                            openSubmissionCallback: (submission) => this.HandleSubmissionModal(true, submission)
                        }"/>

                    <!---------- History list ---------->

                    <SubmissionsList
                        v-if="this.SubmissionsByType.historial?.length"
                        :data="{
                            label: 'Historial',
                            items: this.SubmissionsByType.historial,
                            trashCallback: this.RemoveSelectedSubmissions,
                            openSubmissionCallback: (submission) => this.HandleSubmissionModal(true, submission)
                        }"/>

                </div>

            </div>

        </Transition>

    </DefaultView>

    <Modal v-if="viewConfig.modal" :onclose="()=> { this.CleanInputs(); viewConfig.modal = undefined }" >

        <div class="w-[600px] flex flex-col gap-6">

            <p class="text-xl font-bold text-center">{{ viewConfig.modal.title }}</p>

            <ScrollShadow>

                <div class="flex flex-col gap-3 overflow-y-auto scroll-main pr-1 py-0.5">

                    <div
                        v-for="field in viewConfig.modal.fields" :key="field"
                        class="flex items-center justify-between gap-3">

                        <p class="font-medium">{{ field.title + (field.required ? ' *' : '' )}}</p>

                        <div class="w-full max-w-[280px]">

                            <InputField
                                v-if="field.fieldType === 'text'"
                                size="sm"
                                v-bind="field"
                                v-model="field.value"
                                :required="field.required"/>

                            <InputField
                                v-else-if="field.fieldType === 'dropdown'"
                                class="w-full max-w-xs"
                                placeholder="Selecciona una opción"
                                :items="(field.validValues?.map(o => { return { value: o.key, label: o.text } }) ?? [])"
                                :multiple="false"
                                v-model="field.value"
                                :required="field.required"/>

                            <SimpleCheckbox
                                v-else-if="field.fieldType === 'checkbox'"
                                v-model="field.value"
                                @click="field.value = !field.value"/>

                            <InputField
                                v-else-if="field.fieldType === 'date'"
                                type="date"
                                size="sm"
                                v-bind="field"
                                v-model="field.value"
                                :required="field.required"/>

                            <div v-else-if="field.fieldType === 'file'">

                                <div
                                    v-if="field.value.logo"
                                    class="flex items-center gap-2 p-1 pl-2 rounded bg-second-10">

                                    <i class="icon-image-icon"/>
                                    <p class="w-full truncate text-xs" :title="field.value.logo.name">{{ field.value.logo.name }}</p>
                                    <i class="icon-cross text-3xl cursor-pointer button-hover" @click="field.value.logo = undefined"/>

                                </div>

                                <SelectionDialog
                                    v-else-if="!field.value.logo"
                                    :embedded="false"
                                    v-bind="field"
                                    v-model="field.value.newlogo"
                                    :required="field.required"/>

                            </div>
                            

                        </div>
                        
                    </div>

                </div>

            </ScrollShadow>

            <div class="w-full flex justify-between gap-4">

                <Button
                    variant="outlined"
                    :text="$st('general.cancel')"
                    @click="this.CleanInputs(); viewConfig.modal = undefined"/>

                <Button
                    variant="primary"
                    :text="viewConfig.modal.saveButtonText"
                    :loading="viewConfig.modal.saveButtonLoading"
                    @click="viewConfig.modal.saveButtonClick(viewConfig.modal)"/>

            </div>

        </div>

    </Modal>

</template>

<script>
const { v4: uuidv4 } = require('uuid');

import Button from '@/slango-multiverse/components/inputs/Button'
import InputField from '@/slango-multiverse/components/inputs/InputField'
import Toggle from '@/slango-multiverse/components/inputs/Toggle'
import SimpleCheckbox from '@/slango-multiverse/components/inputs/SimpleCheckbox'
import SelectionDialog from '@/slango-multiverse/components/inputs/SelectionDialog'
import Dropdown from '@/slango-multiverse/components/inputs/Dropdown'
import ScrollShadow from '@/components/ScrollShadow'
import DefaultView from '@/layouts/DefaultView'
import { LocalFlows } from '@/views/Autopilot.vue'

import Modal from '@/slango-multiverse/components/Modal'
import CompanyConfig from './CompanyConfig.vue'
import SubmissionsList from './SubmissionsList.vue'

import { formToFlow, flows } from '@/helpers/APIconnection'
import store from '@/store'
import { globalProperties } from '@/main.js'
import { FlowsController } from '@/views/FlowsPanel'

export const AltasBajasController = {

    GetForms: async () => {

        const response = await formToFlow.submissions.getById('660151a91760c39305e55ae4')

        if ( response?.status === 200 ) { return response.data }
    },

    SyncSubmissions: async ({ flowInputs = {}, flowId = '660151a91760c39305e55ae4', ...data }) => {

        let { submissions, certificates } = flowInputs

        if ( !submissions ) { submissions = flowInputs["input-dataTable"] }
        if ( !certificates ) { certificates = flowInputs["input-certificateData"] }
        
        if ( !submissions?.length || !certificates?.length ) return

        let browserFlow = await flows.getById(flowId)
        if ( browserFlow?.status !== 200 ) return

        browserFlow = browserFlow.data

        const certKey = browserFlow.flowInputs.find(i => i.type == "SelectSingleCertificate").key
        const dataKey = "input-dataTable"

        const flowData = { flowInputs: { [certKey]: certificates, [dataKey]: { value: submissions, type: "LIST" } }, flowId, ...data }

        return await globalProperties.$desktopAppComunication("LaunchFlow", flowData)
    },

    SyncSubmissionsToA3: async ({ flowInputs = {}, flowId = '678a3b0ab75a5be8bc4f60c5', ...data }) => {

        let { submissions } = flowInputs

        if ( !submissions ) { submissions = flowInputs["input-dataTable"] }
        
        if ( !submissions?.length ) return

        let browserFlow = await flows.getById(flowId)
        if ( browserFlow?.status !== 200 ) return

        browserFlow = browserFlow.data
        const documentTypeTable = {
            "1": "D.N.I. / N.I.F.",
            "2": "Pasaporte",
            "6": "N.I.E."
        }

        const flowInputValue = submissions.map((submission) => { 
            const CCC = submission.fieldsValues.CCC
            return {
                "NOMBRE TRABAJADOR": `${submission.fieldsValues.nombre} ${submission.fieldsValues.PrimerApellido} ${submission.fieldsValues.SegundoApellido}`,
                CCC,
                "NÚMERO DOCUMENTO": submission.fieldsValues.NIF,
                "TIPO DOCUMENTO": documentTypeTable[submission.fieldsValues["Tipo de documento"]]?? "D.N.I. / N.I.F.",
                NAF: submission.fieldsValues.NAF,
                EMAIL: submission.fieldsValues.email,
                SEXO: submission.fieldsValues.Sexo,
                "FECHA NACIMIENTO": submission.fieldsValues["Fecha Nacimiento"] ? globalProperties.$formatDate({ date: submission.fieldsValues["Fecha Nacimiento"], format: 'dd/mm/yyyy', div: '' }) : "",
                "INGRESO": globalProperties.$formatDate({ date: submission.fieldsValues["Fecha Alta"], format: 'dd/mm/yyyy', div: '' }),
                "INICIO CONTRATO": globalProperties.$formatDate({ date: submission.fieldsValues["Fecha Inicio"], format: 'dd/mm/yyyy', div: '' }),
                "TIPO CONTRATO": submission.fieldsValues['Tipo Contrato*'],
                "submission": submission,
            } 
        })

        const dataKey = "input-dataTable"
        const flowData = { flowInputs: {[dataKey]: { value: flowInputValue, type: "LIST" }}, flowId, ...data }

        return await globalProperties.$desktopAppComunication("LaunchFlow", flowData)

    }
}

export default {

    components: {
        
        Button,
        SimpleCheckbox,
        Dropdown,
        InputField,
        Toggle, // eslint-disable-line
        CompanyConfig,
        SubmissionsList,
        Modal,
        SelectionDialog,
        ScrollShadow,
        DefaultView
    },

    data: function() {

        return {

            store,
            //TODO NOT HARCODED ID
            flowId: '660151a91760c39305e55ae4',

            submissions: undefined,

            filterByCompany: false,

            pendingsSearchKeyword: '',
            historialSearchKeyword: '',

            viewConfig: {

                view: '',

                modal : undefined,

                loading: {},

                openGroupModal: this.HandleGroupModal,
                openClientModal: this.HandleClientModal,
            },

            userConfig: store.getters.currentUserConfig
        }
    },

    computed: {

        Submissions() { return this.submissions?.filter(s => !s.removed && (!this.filterByCompany || s.fieldsValues?.CCC === this.filterByCompany)) },

        SubmissionsByType() {

            const submissions = {}

            if ( this.Submissions ) {
                
                submissions.historial = this.Submissions.filter((s) => s.processed?.syncSistemaRed)
                submissions.pendings = this.Submissions.filter((s) => !s.processed?.syncSistemaRed)
            }

            return submissions
        },

        PendingSubmissionsWithoutError() { return this.SubmissionsByType.pendings?.filter(s => !s.lastError) },

        filterByCompanyDropdownItems() {

            const clients = [{ label: 'Todos', value: false }]

            if ( !this.viewConfig.clients ) { return clients }

            clients.push(...Object.values(this.viewConfig.clients).filter(c => !c.removed).map(c => { return { label: c.fieldsValues.nombreEmpresa, value: c.fieldsValues.CCC } }))

            return clients
        }
    },

    created() {
        
        this.Init()
    },

    methods: {

        Init: function() {

            this.GetFormToFLow() // Get formToFlow data and normalize it
            this.GetFormToFlowSubmissions() // Get altas/bajas submissions
            this.GetClientsAndGroups() // Clients and groups of formToFlow
        },

        // ====================| Request Data |====================

        GetFormToFLow: async function() {

            this.viewConfig.loading.formToFlow = true
            
            const response = await formToFlow.get(this.flowId)

            if ( response?.status === 200 ) {
                
                this.viewConfig.formToFlow = response.data

                response.data.groupFields = {}
                response.data.clientFields = {}

                Object.entries(response.data.fields).forEach(field => {

                    field[1].key = field[0]

                    if ( field[1].fieldType === 'dropdown' && !field[1].validValues ) { field[1].fieldType = 'text' }

                    if ( field[1].fieldWriter === 'userBefore' ) {

                        if ( field[1].dependentKey ) {
                            
                            response.data.groupFields[field[1].dependentKey] = response.data.fields[field[1].dependentKey]
                            response.data.fields[field[1].dependentKey].parentGroupKey = true

                            response.data.groupFields[field[0]] = field[1]
                        }

                        else { response.data.clientFields[field[0]] = field[1] }
                    }
                })
            }

            this.viewConfig.loading.formToFlow = false
        },

        ManageFormToFlowConfiguration: async function(){

            const ftf = store.state.currentUser.userData.formToFlow
            const hintText = '/form/flows/'
            let value = ftf.url;
            var regex = /\/form\/flows\//g;

            if (regex.test(value)) {
                value = value.replace(regex, "");
            }
            const logoManager = { 
                logo: ftf.logo,
                newlogo: undefined
            }

            const fields = {
                title: { title: "Título", required: true, fieldType: "text", value: ftf.title},
                url: { title: "URL", required: true, fieldType: "text", value , hintText  },
                logo: { title: "Logo", required: false, fieldType: "file", value: logoManager }

            }

            const modal = {}
            modal.title = 'Configuración Formulario'
            modal.fields = fields
            modal.saveButtonText = 'Guardar configuracion'
            modal.saveButtonClick = async (_modal) => {

                _modal.saveButtonLoading = true

                if ( this.$validate() ) {

                    const formConfiguration = {}

                    for (const [fieldName, fieldValue] of Object.entries(fields)) {

                        if (fieldName === "logo") {

                            const { newlogo, logo } = fieldValue.value
                            
                            if (newlogo?.length) { formConfiguration.file = newlogo[0] }
                            else if (logo) { formConfiguration[fieldName] = logo._id }

                        } else { formConfiguration[fieldName] = fieldName === "url" ? hintText + fieldValue.value : fieldValue.value }
                    }
                    
                    const response = await formToFlow.form.put(formConfiguration)

                    if ( response?.status === 200 ) {

                        store.state.currentUser.userData.formToFlow = response.data[store.state.currentUser.userData.uid].formToFlow
                        this.viewConfig.modal = undefined

                        this.$createToastMessage({
                                
                            key: 'success',
                            leadingIcon: 'icon-check-rounded',
                            text: 'Configuración del formulario guardada correctamente.',
                            type: 'secondary',
                            alive: 3000,
                            fixed: true,
                            close: false
                        })
                    
                    } else {

                        this.$createToastMessage({
                                
                            key: 'save-config-form-error',
                            text: 'La URL introducida ya existe.',
                            type: 'error',
                            alive: 3000,
                            fixed: true,
                            close: false
                        })
                    }
                }

                _modal.saveButtonLoading = false
            }

            this.viewConfig.modal = modal
        },

        GetClientsAndGroups: async function() {

            this.viewConfig.loading.clients = true
            
            const configuration = await formToFlow.configuration.get(this.flowId)

            this.viewConfig.clients = {}

            if ( configuration?.status === 200 && configuration.data ) {

                const clients = await formToFlow.client.get(configuration.data._id)

                if ( clients?.status === 200 && clients.data ) {

                    this.viewConfig.clients = clients.data

                    Object.values(clients.data).forEach(this.NormalizeClientGroups)
                }
            }

            this.viewConfig.loading.clients = false
        },

        NormalizeFields: function() {

            Object.values(this.viewConfig.formToFlow.fields).forEach(field => {

                if ( field.value === undefined || field.value === null ) return

                switch ( field.fieldType ) {

                    case 'checkbox':

                        field.value = typeof field.value === 'string' ? field.value.toLowerCase() == "true" : field.value
                        break
                }  
            })
        },
        
        NormalizeClientGroups: function(client) {

            const parentGroupKey = Object.values(this.viewConfig.formToFlow?.fields || {}).find(f => f.parentGroupKey)?.key // Considerando que solo hay un campo padre
            client.groups = client.fields[parentGroupKey]?.validValues
        },

        GetFormToFlowSubmissions: async function() {

            this.viewConfig.loading.submissions = true
            
            const forms = await AltasBajasController.GetForms()

            if ( forms ) this.submissions = forms

            this.viewConfig.loading.submissions = false
        },

        // ====================| Flow management |====================

        CheckSelectedSubmissions: function() {

            const selectedSubmissions = this.Submissions.filter(p => p.selected)
            
            if ( !selectedSubmissions?.length ) {

                this.$createToastMessage({

                    key: 'no-selected',
                    leadingIcon: 'icon-warning',
                    text: 'Necesitas seleccionar al menos un Alta para continuar.',
                    type: 'error',
                    alive: 3000,
                    fixed: true,
                    close: false
                })

                return
            }

            return selectedSubmissions
        },

        OnSubmissionsLaunch: async function(submissions = this.CheckSelectedSubmissions()) {

            if ( !submissions?.length ) return

            store.state.ui.promptModal = {

                title: 'Selecciona un certificado',
                userResourcesManager: { selectable: false },
                footer: [
                    { variant: 'outlined', text: this.$st('general.cancel'), click: () => store.state.ui.promptModal = undefined },
                    { text: this.$st('general.launch'), onclick: () => {

                        const certificates = store.state.ui.promptModal.userResourcesManager.selected

                        this.ContinueIfSelectedCertificate(certificates, () => this.LaunchFlow(submissions, certificates))
                    }}
                ]
            }
        },

        OnSubmissionSyncWithA3: async function() {

            const submissions = this.CheckSelectedSubmissions()

            if ( !submissions?.length ) return

            store.state.ui.promptModal = {

                title: 'Selecciona en qué máquina quieres sincronizar',
                userResourcesManager: { selectable: true, machines: true, certificates: false, multiple: false, currentMachine: true },
                footer: [
                    { variant: 'outlined', text: this.$st('general.cancel'), click: () => store.state.ui.promptModal = undefined },
                    { text: 'Sincronizar con A3', onclick: async () => {

                        const selectedMachine = store.state.ui.promptModal.userResourcesManager.selected
                        const isRemoteLaunch = !!selectedMachine?._id

                        if ( typeof selectedMachine !== 'object' || Array.isArray(selectedMachine) ) return

                        if ( isRemoteLaunch ) {

                            await FlowsController.Schedule({ flowInputs: { submissions }, machine: selectedMachine, localProcess: 'sync-altas-a3', processName: LocalFlows['sync-altas-a3']?.title })
                        }

                        else {

                            const response = await AltasBajasController.SyncSubmissionsToA3({ flowInputs: { submissions } })
                            if ( response ) { this.$addRobotLogNotification(response) }

                            this.Init()
                            store.state.ui.promptModal = undefined
                        }
                    }}
                ]
            }
        },

        CleanInputs: function() {

            Object.values(this.viewConfig.formToFlow?.fields || {}).forEach(f => delete f.value)
        },

        IsSelectedMachine: function() {},

        ContinueIfSelectedCertificate: function(certificates, callback) {

            if ( !certificates?.length ) {

                this.$createToastMessage({
                                
                    key: 'no-selected',
                    leadingIcon: 'icon-warning',
                    text: 'Necesitas seleccionar un certificado para continuar.',
                    type: 'error',
                    alive: 3000,
                    fixed: true,
                    close: false
                })
                
                return
            }

            callback()
        },

        LaunchFlow: async function(submissions, certificates) {

            this.viewConfig.loading.running = true

            const { hasSharedCertificateSelected } = store.state.ui.promptModal.userResourcesManager
            store.state.ui.promptModal = undefined

            if ( hasSharedCertificateSelected ) {
                
                const machine = certificates.find(v => v.machine)?.machine
                await FlowsController.Schedule({ flowInputs: { submissions, certificates }, machine, localProcess: 'sync-altas', processName: LocalFlows['sync-altas']?.title })
            }

            else {

                const response = await AltasBajasController.SyncSubmissions({ flowInputs: { submissions, certificates } })

                if ( response ) { this.$addRobotLogNotification(response) }

                this.Init()
                this.CleanInputs()
            }

            this.viewConfig.loading.running = false
            this.viewConfig.modal = undefined
        },

        RemoveSelectedSubmissions: function(selectedSubmissions) {

            if ( !selectedSubmissions ) return

            store.state.ui.promptModal = {

                title: "¿ Estás seguro/a ?",
                text: `Vas a eliminar la selección de historial o pendientes.`,
                footer: [

                    { click: () => store.state.ui.promptModal = undefined, text: "Cancelar", variant: 'outlined' },
                    { click: async () => {

                        const deletionPromises = selectedSubmissions.map(async submission => {
                            submission.loading = true
                            const response = await formToFlow.submissions.delete(submission._id);
                            if (response.status == 200) { submission.removed = true; submission.selected = false; }
                            return response;
                        });

                        await Promise.all(deletionPromises);

                        store.state.ui.promptModal = undefined

                    }, text: "Eliminar" }
                ]
            }
        },

        HandleGroupModal: function(editing, client, groupKey, groupName) { // eslint-disable-line

            const modal = {}

            if ( editing ) {

                Object.entries(client.fields).forEach(field => {

                    this.viewConfig.formToFlow.fields[field[0]].value = field[1].validValues?.find(v => v.key === groupKey)?.text || field[1].dependentOptions?.[groupKey]
                })

                this.NormalizeFields()

                modal.title = `Editar “${groupName}”`
                modal.fields = this.viewConfig.formToFlow?.groupFields
                modal.saveButtonText = 'Guardar grupo'
                modal.saveButtonClick = async (_modal) => {

                    _modal.saveButtonLoading = true

                    if ( this.$validate() ) { await this.PushGroup(true, client, groupKey) }

                    _modal.saveButtonLoading = false
                }
            }

            else {

                modal.title = 'Nuevo Grupo'
                modal.fields = this.viewConfig.formToFlow?.groupFields
                modal.saveButtonText = 'Guardar nuevo grupo'
                modal.saveButtonClick = async (_modal) => {

                    _modal.saveButtonLoading = true

                    if ( this.$validate() ) { await this.PushGroup(false, client, uuidv4()) }

                    _modal.saveButtonLoading = false
                }
            }

            this.viewConfig.modal = modal
        },

        HandleClientModal: function(editing, client) { // eslint-disable-line

            const modal = {}

            if ( editing ) {

                Object.entries(client.fieldsValues).forEach(field => { this.viewConfig.formToFlow.fields[field[0]].value = field[1] })

                this.NormalizeFields()

                modal.title = `Editar “${client.fieldsValues.nombreEmpresa}”`
                modal.fields = this.viewConfig.formToFlow?.clientFields
                modal.saveButtonText = 'Guardar empresa'
                modal.saveButtonClick = async (_modal) => {

                    _modal.saveButtonLoading = true

                    if ( this.$validate() ) { await this.EditClient(client) }

                    _modal.saveButtonLoading = false
                }
            }

            else {

                modal.title = 'Nueva Empresa'
                modal.fields = this.viewConfig.formToFlow?.clientFields
                modal.saveButtonText = 'Guardar nueva empresa'
                modal.saveButtonClick = async (_modal) => {

                    _modal.saveButtonLoading = true

                    if (this.$validate()) { await this.CreateClient() }

                    _modal.saveButtonLoading = false
                } 
            }

            this.viewConfig.modal = modal
        },

        HandleSubmissionModal: function(editing, submission) { // eslint-disable-line

            const modal = {}
            
            if ( editing ) {

                Object.entries(submission.fieldsValues).forEach(field => {
                    
                    const f = this.viewConfig.formToFlow.fields[field[0]]
                    if ( f ) f.value = field[1]
                })

                this.NormalizeFields()

                modal.title = `Editar “${submission.title}”`
                modal.fields = this.viewConfig.formToFlow?.fields
                modal.saveButtonText = 'Guardar alta'
                modal.saveButtonClick = async (_modal) => {

                    _modal.saveButtonLoading = true

                    if ( this.$validate() ) { await this.EditSubmission(submission) }

                    _modal.saveButtonLoading = false
                }
            }

            else {

                modal.title = 'Nueva Alta'
                modal.fields = this.viewConfig.formToFlow?.fields
                modal.saveButtonText = 'Lanzar alta ahora'
                modal.saveButtonClick = async (_modal) => {

                    _modal.saveButtonLoading = true

                    if ( this.$validate() ) { this.NewSubmission() }

                    _modal.saveButtonLoading = false
                }
            }

            this.viewConfig.modal = modal
        },

        PushGroup: async function(editing, client, selectedGroupKey) {

            const parentGroupKey = Object.values(this.viewConfig.formToFlow.groupFields).find(f => f.parentGroupKey)?.key

            const groupData = {}
            const group = { [selectedGroupKey]: groupData }

            Object.entries(this.viewConfig.formToFlow.groupFields).forEach(field => { groupData[field[0]] = field[1].value })

            const response = editing ? await formToFlow.group.patch(client._id, parentGroupKey, group) : await formToFlow.group.post(client._id, parentGroupKey, group)

            if ( response.status == 200 ) {
                
                Object.assign(client, response.data)

                this.NormalizeClientGroups(client)
                
                this.viewConfig.modal = undefined
                this.CleanInputs()

                this.$createToastMessage({
                                
                    key: 'success',
                    leadingIcon: 'icon-check-rounded',
                    text: editing ? 'El grupo se ha actualizado correctamente' : 'El grupo se ha creado correctamente',
                    type: 'secondary',
                    alive: 3000,
                    fixed: true,
                    close: false
                })
            }
        },

        CreateClient: async function() {

            let configuration
            const configPromise = new Promise(res => formToFlow.configuration.get(this.flowId).then(res)).then(r => configuration = r.data)
           
            // Create client if is not already created
            
            let userClient = Object.values(this.viewConfig.clients || {}).find(c => c.fieldsValues.email === this.viewConfig.formToFlow?.clientFields.email.value)

            if ( !userClient ) {

                const res = await formToFlow.user.client.post({name: this.viewConfig.formToFlow?.clientFields["nombreEmpresa"].value, email: this.viewConfig.formToFlow?.clientFields["email"].value})

                if ( res?.status === 200 ) { userClient = res.data }
            }

            // FormToFlow configuration

            await configPromise

            if ( !configuration ) {

                const newconfig = { name: this.viewConfig.formToFlow.title, formPages: this.viewConfig.formToFlow.defaultFormPages }
                const res = await formToFlow.configuration.patch(this.flowId, newconfig)

                if ( res?.status === 200 ) { configuration = res.data }
                else return
            }

            // >> Create company

            const fieldsValues = {}

            Object.entries(this.viewConfig.formToFlow?.clientFields || {}).forEach(field => { fieldsValues[field[0]] = field[1].value })

            const newClient = {
                
                credentials: { client: userClient._id, enabled: true },
                formToFlowConfiguration: configuration._id,
                fieldsValues 
            }

            const response = await formToFlow.client.patch(newClient)

            if ( response?.status === 200 && response.data ) {

                this.viewConfig.clients[userClient._id] = response.data
                this.viewConfig.modal = undefined
                this.CleanInputs()

                this.$createToastMessage({
                                
                    key: 'success',
                    leadingIcon: 'icon-check-rounded',
                    text: 'Empresa creada correctamente',
                    type: 'secondary',
                    alive: 3000,
                    fixed: true,
                    close: false
                })
            }

            else {

                this.$createToastMessage({
                                
                    key: 'already-created',
                    leadingIcon: 'icon-warning',
                    text: 'Este email ya está en uso',
                    type: 'error',
                    alive: 3000,
                    fixed: true,
                    close: false
                })
            }
        },

        EditClient: async function(selectedClient) {

            const clientFields = this.viewConfig.formToFlow.clientFields
            
            const res = await formToFlow.user.client.patch(selectedClient.credentials.client, {
                
                name: clientFields["nombreEmpresa"].value,
                email: clientFields["email"].value
            })

            if ( res?.status === 200 ) {

                const userClient = res.data

                const fieldsValues = {}

                Object.entries(this.viewConfig.formToFlow?.clientFields || {}).forEach(field => { fieldsValues[field[0]] = field[1].value })

                const newClient = {

                    credentials: { client: userClient._id, enabled: true},
                    formToFlowConfiguration: selectedClient.formToFlowConfiguration,
                    fieldsValues 
                }

                const response = await formToFlow.client.patch(newClient)

                if ( response?.status === 200 && response.data ) {

                    Object.assign(this.viewConfig.clients[userClient._id], response.data)

                    this.viewConfig.modal = undefined
                    this.CleanInputs()

                    this.$createToastMessage({
                                
                        key: 'success',
                        leadingIcon: 'icon-check-rounded',
                        text: 'Empresa actualizada correctamente',
                        type: 'secondary',
                        alive: 3000,
                        fixed: true,
                        close: false
                    })
                }
            }
        },

        NewSubmission: async function() {
            
            const newSubmission = this.GetSubmissionFromFTFFields()

            if ( !newSubmission ) return

            this.OnSubmissionsLaunch([newSubmission])
        },

        EditSubmission: async function(submission) {

            const newSubmissionFieldValues = this.GetSubmissionFromFTFFields()

            const response = await formToFlow.submissions.patch(submission._id, { fieldsValues : newSubmissionFieldValues })

            if ( response?.status === 200 ) {

                submission.fieldsValues = response.data.fieldsValues
                submission.title = `${submission.fieldsValues.nombre} ${submission.fieldsValues.PrimerApellido ?? ''} ${submission.fieldsValues.SegundoApellido ?? ''} - ${submission.fieldsValues.nombreEmpresa}`
                this.viewConfig.modal = undefined
                this.CleanInputs()

                this.$createToastMessage({
                                
                    key: 'success',
                    leadingIcon: 'icon-check-rounded',
                    text: 'Alta actualizada correctamente',
                    type: 'secondary',
                    alive: 3000,
                    fixed: true,
                    close: false
                })
            }
        },

        GetSubmissionFromFTFFields: function() {

            this.NormalizeFields()

            return Object.fromEntries(Object.entries(this.viewConfig.formToFlow?.fields || {}).map(f => { return [f[0], f[1].value] }))
        }
    }
}
</script>